import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'

import DatePicker from "react-datepicker"
import dayjs from "dayjs"

import { Dialog } from "../../components/Dialog"
import { useGetQueryParams } from "../../services/useGetQuery"
import { getDate } from "../../store/schoolBusSchedulePageSlice"
import { getLocale } from "../../store/commonSlice"
import { PrimaryButton, SecondaryButton } from "../../components/Button"

import "react-datepicker/dist/react-datepicker.css";
import "./ScheduleFilters.scss"
import { IconTable } from "../../resources/icons"

const ScheduleFilters = ({ date, locale }) => {

    const history = useHistory()
    const routeMatch = useRouteMatch()
    const queryParams = useGetQueryParams()
    const intl = useIntl()

    const [localeData, setLocaleData] = useState(null)
    const [datepickerOpen, setDatepickerOpen] = useState(false)
    const [chosenDate, setChosenDate] = useState()

    useEffect(() => {
        if (queryParams.date) {
            setChosenDate(new Date(queryParams.date))
        } else {
            onDateChange(new Date())
        }
    }, [queryParams])

    useEffect(() => {
        if (locale) {
            import(`date-fns/locale/${locale}/index.js`)
                .then(newlocale => {
                    setLocaleData(newlocale)
                })
        }
    }, [locale])

    const onDateChange = (newDate) => {
        const formatedDate = dayjs(newDate).format("YYYY-MM-DD")
        history.push(`${routeMatch.path}?${new URLSearchParams({ ...queryParams, date: formatedDate }).toString()}`)
    }

    const closeDatepicker = () => {
        setDatepickerOpen(false)
        setChosenDate(new Date(date))
    }

    const openDatepicker = () => {
        setDatepickerOpen(true)
        setChosenDate(new Date(date))
    }

    const onSetDateClick = () => {
        onDateChange(chosenDate)
        closeDatepicker()
    }

    const onChange = (newDate) => {
        setChosenDate(new Date(newDate))
    }

    const onSetToday = () => {
        setChosenDate(new Date())
    }

    const onSetTomorrow = () => {
        setChosenDate(dayjs().add(1, "day").toDate())
    }

    return (
        <div className="sfp-school-bus-schedule-filter-wrapper">
            <div className="sfp-school-bus-schedule-date">
                <IconTable className="icon" />
                <span>{
                    intl.formatMessage({ id: "pages.schoolBusStationSchedule.filter.datepicker.scheduleFor" })
                }</span>
                <button
                    className="spf-open-datepicker-button"
                    onClick={openDatepicker}
                >
                    {
                        queryParams.date ?
                            dayjs(queryParams.date).format("DD MMMM") :
                            dayjs().format("DD MMMM")
                    }
                </button>
            </div>

            <Dialog
                opened={datepickerOpen}
                handleCloseDialog={closeDatepicker}
                className="sfp-datepicker-dialog"
                title={intl.formatMessage({ id: "pages.schoolBusStationSchedule.filter.datepicker.title" })}
            >
                <div className="sfp-datepicker-dialog-body">
                    <SecondaryButton
                        onClick={
                            onSetToday
                        }>
                        {intl.formatMessage({ id: "pages.schoolBusStationSchedule.filter.datepicker.button.today" })
                        }
                    </SecondaryButton>
                    <SecondaryButton
                        onClick={
                            onSetTomorrow
                        }>
                        {intl.formatMessage({ id: "pages.schoolBusStationSchedule.filter.datepicker.button.tomorrow" })}
                    </SecondaryButton>
                    <div className="sfp-datepicker-container">
                        <DatePicker
                            selected={chosenDate ? new Date(chosenDate) : new Date()}
                            onChange={onChange}
                            dateFormat="dd MMMM"
                            allowSameDay={true}
                            calendarClassName="sfp-datepicker"
                            locale={localeData ? localeData : "en"}
                            inline
                        />
                    </div>
                    <PrimaryButton
                        onClick={
                            onSetDateClick
                        }>
                        {intl.formatMessage({ id: "pages.schoolBusStationSchedule.filter.datepicker.button.pickDate" })}
                    </PrimaryButton>
                </div>
            </Dialog>
        </div>
    )
}

export default connect(
    (state) => ({
        date: getDate(state),
        locale: getLocale(state)
    }),
    ({})
)(ScheduleFilters)