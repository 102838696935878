import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useRouteMatch, Redirect, Route, Switch } from 'react-router-dom'

import { getStationInfo, pageOpened, pageClosed } from '../../store/schoolBusSchedulePageSlice'
import { useGetQueryParams } from "../../services/useGetQuery"

import StationInfo from "./StationInfo"
import ScheduleView from "./ScheduleView"
import ScheduleFilters from "./ScheduleFilters"

const SchoolBusStationSchedulePage = ({ stationInfo, pageClosed, pageOpened }) => {
  const routeMatch = useRouteMatch()
  const { sid, date } = useGetQueryParams()

  useEffect(() => {
    pageOpened({ stationId: sid, date: date })
    return (() => {
      pageClosed()
    })
  }, [pageOpened, pageClosed, sid, date])

  return (
    <>
      <StationInfo />
      <Switch>
        <Route path={routeMatch.path} exact>
          <ScheduleFilters />
          <ScheduleView />

        </Route>

        <Route>
          <Redirect to={routeMatch.path} />
        </Route>
      </Switch>

    </>
  )
}

export default connect(
  state => ({
    stationInfo: getStationInfo(state),
  }),
  ({
    pageOpened,
    pageClosed
  })
)(SchoolBusStationSchedulePage)