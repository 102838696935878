import React from 'react'
import PropTypes from 'prop-types'

import IconFlagEng from './IconFlagEng'
import IconFlagRus from './IconFlagRus'
import IconFlagChv from './IconFlagChv'
import IconFlagTur from './IconFlagTur'


const propTypes = {
    flag: PropTypes.oneOf(['england', 'russia', 'chuvash', 'turkey']),
}

const Icon = ({ flag }) => {
    switch (flag) {
        case 'england':
            return <IconFlagEng />
        case 'russia':
            return <IconFlagRus />
        case 'chuvash':
            return <IconFlagChv />
        case 'turkey':
            return <IconFlagTur />
        default:
            return null
    }
}

Icon.propTypes = propTypes

export default Icon
