import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { debounce } from 'lodash-es'

import {
    fetchStationsByKeyworld,
    getStationsLoaded,
    clearStations,
    getStationsLoading,
    getHasError,
    getStations,
} from '../../store/stationSearchByKeywordPageSlice'
import { useGetQueryParams } from '../../services/useGetQuery'
import { IconClear } from '../../resources/icons'
import StationSearchPageTitle from '../../components/StationSearch/PageTitle'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import StationsList from '../../components/StationSearch/StationsList'
import config from '../../resources/config'

import EmptyScreen from './EmptyScreen'
import './StationSearchByKeywordPage.scss'

const ON_INPUT_SEARCH_DEBOUNCE_DELAY = config.pages.stationSearchByKeywordPage.onInputSearchDebounceDelay

/* eslint react/prop-types: ["off"] */
const StationSearchByKeywordPage = ({
    fetchStationsByKeyworld,
    clearStations,
    stationsLoaded,
    hasError,
    loading,
    stations,
}) => {
    const intl = useIntl()
    const history = useHistory()
    const routeMatch = useRouteMatch()
    const { q } = useGetQueryParams()

    const [searchQuery, setSearchQuery] = useState('')

    const onInput = useCallback(e => {
        setSearchQuery(e.target.value)
    }, [setSearchQuery])

    useEffect(() => {
        if (searchQuery.length >= 3) {
            debouncedPushSearchToQuery(routeMatch.path, searchQuery)
        }
        return () => {
            debouncedPushSearchToQuery.cancel()
        }
    }, [searchQuery])

    useEffect(() => {
        if (q) {
            setSearchQuery(q)
            const query = q.trim()
            if (query.length) {
                fetchStationsByKeyworld(query)
            }
        } else {
            setSearchQuery('')
            clearStations()
        }
    }, [q, fetchStationsByKeyworld, clearStations])

    const searchEnterPressed = (e) => {
        if (e.key === 'Enter') {
            pushSearchToQuery(routeMatch.path, e.target.value)
        }
    }

    const pushSearchToQuery = (path, query) => {
        history.push(`${path}?q=${encodeURIComponent(query)}`)
    }

    const debouncedPushSearchToQuery = useRef(debounce((routePath, query) => {
        pushSearchToQuery(routePath, query)
    }, ON_INPUT_SEARCH_DEBOUNCE_DELAY)).current

    const clearQuery = () => {
        setSearchQuery('')
        history.push(routeMatch.path)
    }

    return (
        <>
            <StationSearchPageTitle
                title={intl.formatMessage({
                    id: 'pages.stationSearchByKeyword.title',
                })}
                headTitle={intl.formatMessage({
                    id: 'pages.stationSearchByKeyword.htmlHead.title',
                })}
            />
            <div className="stationSearchByKeywordPage-searchFieldWrapper">
                <input
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    className="search-input"
                    placeholder={intl.formatMessage({
                        id: 'pages.stationSearchByKeyword.searchField.placehoder',
                    })}
                    value={searchQuery}
                    onChange={onInput}
                    onKeyPress={searchEnterPressed}
                />
                {searchQuery.length > 0 && (
                    <div className="icon-clear" onClick={clearQuery} aria-hidden>
                        <IconClear />
                    </div>
                )}
            </div>
            {loading && (
                <LoadingScreen
                    message={intl.formatMessage({
                        id: 'pages.stationSearchByKeyword.LoadingScreen',
                    })}
                />
            )}
            {hasError && (
                <ErrorScreen
                    message={intl.formatMessage({
                        id: 'pages.stationSearchByKeyword.ErrorScreen',
                    })}
                />
            )}
            {!loading && !hasError && (
                <>
                    {!stationsLoaded && <EmptyScreen />}
                    {stationsLoaded && (
                        <StationsList
                            stations={stations}
                            notFoundMessage={intl.formatMessage(
                                {
                                    id: 'pages.stationSearchByKeyword.NotFound',
                                },
                                {
                                    br: <br key={0} />,
                                },
                            )}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default connect(
    state => ({
        stationsLoaded: getStationsLoaded(state),
        hasError: getHasError(state),
        loading: getStationsLoading(state),
        stations: getStations(state),
    }),
    {
        fetchStationsByKeyworld,
        clearStations,
    },
)(StationSearchByKeywordPage)
