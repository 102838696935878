import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { IconArrowForward, IconStation } from '../../../resources/icons'
import NotFoundScreen from '../NotFoundScreen'

import transliterator from "../../../services/transliterator"

import './StationsList.scss'


const propTypes = {
    stations: PropTypes.arrayOf(PropTypes.object).isRequired,
    notFoundMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    showDistances: PropTypes.bool,
}

const StationsList = ({ stations, notFoundMessage, showDistances = false }) => {
    const intl = useIntl()

    if (stations.length === 0) {
        return <NotFoundScreen message={notFoundMessage} />
    }

    return (
        <div className="ss-station-list-wrapper">
            {stations.map(item => (
                <Link key={item.id} to={`/station_forecasts?sid=${item.id}`} className="list-item">
                    <div className="list-item-icon-wrapper">
                        <IconStation routeTypes={item.routeTypes} iconType="place" />
                    </div>
                    <div className="list-item-name-wrapper">
                        <div className="list-item-name">
                            {transliterator().transliterate(item.name)}
                        </div>
                        <div className="list-item-description">
                            {transliterator().transliterate(item.description)}
                        </div>
                    </div>

                    {
                        showDistances
                            ? (
                                <div className="list-item-distance">
                                    {item.dist}
                                    &nbsp;
                                    {intl.formatMessage({
                                        id: 'pages.stationSearchByLocation.units.meters',
                                    })}
                                </div>
                            )
                            : (
                                <IconArrowForward />
                            )
                    }
                </Link>
            ))}
        </div>
    )
}

StationsList.propTypes = propTypes

export default StationsList
