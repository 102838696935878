import {pageOpened, fetchStationInfo, fetchSchedules} from './schoolBusSchedulePageSlice'

export const schoolBusSchedulePageMiddleware = (store) => {

    return next => (action) => {
        next(action)

        // Добывем первоначальные данные
        if (action.type === pageOpened.toString()) {
            store.dispatch(fetchStationInfo())
            store.dispatch(fetchSchedules())
        }
  
    }
}
