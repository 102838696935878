import axios from 'axios'
import { createSelector, createSlice } from '@reduxjs/toolkit'

export const MAP_PAGE_REDUCER_KEY = 'mapPage'

const mapPageSlice = createSlice({
    name: MAP_PAGE_REDUCER_KEY,
    initialState: {
        positions: [],
        filters: {},
    },
    reducers: {
        setPositions: (state, { payload: positions }) => {
            state.positions = positions
        },
        unloadTransportPositions: state => {
            state.positions = []
        },
        setFilters: (state, { payload }) => {
            state.filters = payload
        },
    },
})

export const { unloadTransportPositions, setFilters } = mapPageSlice.actions

export const getMapPage = state => state.pages[MAP_PAGE_REDUCER_KEY]
export const getPositions = state => getMapPage(state).positions
export const getFilters = state => getMapPage(state).filters

export const fetchTransportPositions =
    ({ filters, currentAnimKey }) =>
    async dispatch => {
        try {
            const params = { rids: null, curk: currentAnimKey }

            if (filters?.routesFilter && Array.isArray(filters?.routesFilter)) {
                params.rids = filters.routesFilter.join(',')
            }

            const { data } = await axios.get('/getVehicleAnimationsNoProtect.php', {
                params,
            })

            //const {data} = await import("./../store/positions-test")

            const positions = data.map(item => ({
                id: item.id,
                lat: +item.lat / 1000000,
                lng: +item.lng / 1000000,
                dir: +item.dir,
                rtype: item.rtype,
                rnum: item.rnum,
                gos_num: item.gos_num,
                rid: item.rid,
                anim_key: item.anim_key,
                big_jump: item.big_jump,
                anim_points: item.anim_points,
                wifi: item.wifi,
                low_floor: item.low_floor,
                flags: item.flags,
            }))

            dispatch(mapPageSlice.actions.setPositions(positions))
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
            // FIXME
            // dispatch(stationForecastPageSlice.actions.forecastsFetchFail(e.toString()))
        }
    }

export default mapPageSlice.reducer
