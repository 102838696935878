import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'
import { push as navigateTo } from 'connected-react-router'

export const SCHOOL_BUS_SCHEDULE_PAGE_SLICE = 'schoolBusSchedulePage'

const schoolBusSchedulePageSlice = createSlice({
    name: SCHOOL_BUS_SCHEDULE_PAGE_SLICE,
    initialState: {
        stationId: null,
        date: null,
        stationInfo: {},
        schedules: [],
        schedulesPendind: false,
    },
    reducers: {
        pageOpened: (state, { payload: { stationId, date } }) => {
            state.stationId = stationId
            state.date = date
            state.stationInfo = {}

            return state
        },
        pageClosed: state => {
            state.stationId = null
            state.stationInfo = {}
            state.date = null

            return state
        },
        setStationId: (state, { payload }) => {
            state.stationId = payload
        },
        setDate: (state, { payload }) => {
            state.date = payload
        },
        setStationInfo: (state, { payload }) => {
            state.stationInfo = payload
        },
        setSchedules: (state, { payload }) => {
            state.schedules = payload
        },

        fetchSchdulesFail: state => {
            state.schedulesPending = false
            state.schedules = []

            return state
        },
    },
})

export const { pageOpened, pageClosed, setSchedules, setStationId, setStationInfo, setDate } =
    schoolBusSchedulePageSlice.actions

export const getSchoolBusSchedulePage = state => state.pages[SCHOOL_BUS_SCHEDULE_PAGE_SLICE]

export const getStationId = state => getSchoolBusSchedulePage(state).stationId

export const getStationInfo = state => getSchoolBusSchedulePage(state).stationInfo

export const getSchedules = state => getSchoolBusSchedulePage(state).schedules

export const getDate = state => getSchoolBusSchedulePage(state).date

export const fetchStationInfo = () => async (dispatch, getState) => {
    let stationId
    const state = getState()

    try {
        stationId = getStationId(state)

        const { data } = await axios.get('/getStationInfo.php', { params: { sid: stationId } })

        const currentState = getState()
        const currentStationId = getStationId(currentState)
        if (currentStationId === stationId) {
            if (data && data.name && data.name.length) {
                dispatch(setStationInfo(data))
            } else if (stationId !== '76') {
                // Нужна проверка для предотвращения зацикливания, т.к. 76 это дефолтный id при открытии
                // eslint-disable-next-line no-console
                console.error(`Остановка с id="${stationId}" не найдена`)
                dispatch(navigateTo('/'))
            }
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Ошибка получения остановки с id="${stationId}": `, e)
        dispatch(navigateTo('/'))
    }
}

export const fetchSchedules = () => async (dispatch, getState) => {
    const state = getState()
    try {
        const sid = getStationId(state)
        const date = getDate(state)

        const params = { sid: sid, date: date }

        const { data } = await axios
            .get('/getStationScheduleTable.php', {
                params,
            })
            .catch(error => {
                console.error(error)
                return { data: [] }
            })

        const schedules = data

        dispatch(schoolBusSchedulePageSlice.actions.setSchedules(schedules))
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        // FIXME
        // dispatch(stationForecastPageSlice.actions.forecastsFetchFail(e.toString()))
    }
}

export default schoolBusSchedulePageSlice.reducer
