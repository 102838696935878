import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import map from 'lodash-es/map'

import {
    getTransportsIconsVisible,
    setTransportsIconsVisible,
    getTransportsNumberVisible,
    setTransportsNumberVisible,
    getThemeColor,
    getThemeModifier,
    setTheme,
    getTableHeaderVisible,
    setTableHeaderVisible,
    getLocale,
    setLocale,
} from '../../store/commonSlice'
import { LANG_MAP } from '../../resources/lang'
import { DialogLineCheckbox, Dialog } from '../../components/Dialog'
import { IconFlag, IconGlasses, IconNumberView, IconTable, IconWheelchairSquare } from '../../resources/icons'
import { THEME_COLOR, THEME_MODIFIER } from '../../resources/config/theme-constants'
import config from '../../resources/config'

import './SettingsDialog.scss'

const langs = Object.values(LANG_MAP).filter((lang) => config.common.langs.includes(lang.locale))

const propTypes = {
    opened: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
}

/* eslint react/prop-types: ["error", { ignore: [transportsIconsVisible, setTransportsIconsVisible, transportsNumberVisible, setTransportsNumberVisible, themeColor, themeModifier, setTheme, tableHeaderVisible, setTableHeaderVisible, locale, setLocale] }] */
const LocaleDialogBody = ({
    opened,
    handleCloseDialog,
    transportsIconsVisible,
    setTransportsIconsVisible,
    transportsNumberVisible,
    setTransportsNumberVisible,
    themeColor,
    themeModifier,
    setTheme,
    tableHeaderVisible,
    setTableHeaderVisible,
    locale,
    setLocale,
}) => {
    const intl = useIntl()

    const toggleTransportsIconsVisibleHandler = useCallback(() => {
        setTransportsIconsVisible(!transportsIconsVisible)
    }, [transportsIconsVisible, setTransportsIconsVisible])

    const toggleTransportsNumberVisibleHandler = useCallback(() => {
        setTransportsNumberVisible(!transportsNumberVisible)
    }, [transportsNumberVisible, setTransportsNumberVisible])

    const isLightTheme = themeColor === THEME_COLOR.light && themeModifier === THEME_MODIFIER.normal
    const toggleThemeHandler = useCallback(() => {
        if (isLightTheme) {
            setTheme({ themeColor: THEME_COLOR.dark, themeModifier: THEME_MODIFIER.normal })
        } else {
            setTheme({ themeColor: THEME_COLOR.light, themeModifier: THEME_MODIFIER.normal })
        }
    }, [isLightTheme, setTheme])

    const toggleTableHeaderVisibleHandler = useCallback(() => {
        setTableHeaderVisible(!tableHeaderVisible)
    }, [tableHeaderVisible, setTableHeaderVisible])

    // locale
    const selectLangHandler = useCallback(
        (locale) => {
            setLocale(locale)
            handleCloseDialog()
        },
        [setLocale, handleCloseDialog],
    )

    if (!opened) {
        return null
    }

    return (
        <Dialog
            opened
            handleCloseDialog={handleCloseDialog}
            title={intl.formatMessage({ id: 'pages.stationForecasts.footer.menu.settings' })}
            className="setting-dialog"
        >
            <div className="settings-section">
                <DialogLineCheckbox
                    checked={transportsIconsVisible}
                    title={intl.formatMessage({ id: 'pages.stationForecasts.popup.settings.showTags' })}
                    handleCheck={toggleTransportsIconsVisibleHandler}
                >
                    <IconWheelchairSquare />
                </DialogLineCheckbox>
                <DialogLineCheckbox
                    checked={transportsNumberVisible}
                    title={intl.formatMessage({ id: 'pages.stationForecasts.popup.settings.showGosNumber' })}
                    handleCheck={toggleTransportsNumberVisibleHandler}
                >
                    <IconNumberView />
                </DialogLineCheckbox>
                <DialogLineCheckbox
                    checked={!isLightTheme}
                    title={intl.formatMessage({ id: 'pages.stationForecasts.popup.settings.contrastMode' })}
                    handleCheck={toggleThemeHandler}
                >
                    <IconGlasses />
                </DialogLineCheckbox>
                <DialogLineCheckbox
                    checked={tableHeaderVisible}
                    title={intl.formatMessage({ id: 'pages.stationForecasts.popup.settings.showTableHeader' })}
                    handleCheck={toggleTableHeaderVisibleHandler}
                >
                    <IconTable />
                </DialogLineCheckbox>
            </div>
            <div className="settings-section">
                <div className="settings-section-title">
                    {intl.formatMessage({ id: 'pages.stationForecasts.title.menu.chooseLang' })}
                </div>
                {map(langs, lang => (
                    <DialogLineCheckbox
                        key={lang.locale}
                        type="radio"
                        checked={lang.locale === locale}
                        title={lang.label}
                        handleArg={lang.locale}
                        handleCheck={selectLangHandler}
                    >
                        <IconFlag flag={lang.flag} />
                    </DialogLineCheckbox>
                ))}
            </div>
        </Dialog>
    )
}

const LocaleDialog = connect(
    state => ({
        transportsIconsVisible: getTransportsIconsVisible(state),
        transportsNumberVisible: getTransportsNumberVisible(state),
        themeColor: getThemeColor(state),
        themeModifier: getThemeModifier(state),
        tableHeaderVisible: getTableHeaderVisible(state),
        locale: getLocale(state),
    }),
    {
        setTransportsIconsVisible,
        setTransportsNumberVisible,
        setTheme,
        setTableHeaderVisible,
        setLocale,
    },
)(LocaleDialogBody)

LocaleDialogBody.propTypes = propTypes
LocaleDialog.propTypes = propTypes

export default LocaleDialog
