import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import { intersects } from '../../../services/utils'
import CheckBox from '../../../components/Checkbox'
import Accordition from '../../../components/Accordition'
import {
    getExceptedRoutesForStation,
    getStationInfoRoutes,
    isExceptedTransportTypeForCurrentStation,
    toggleExceptedRoutes,
} from '../../../store/stationForecastPageSlice'
import { useIntl } from 'react-intl'

/* eslint react/prop-types: ["off"] */
const RoutesFilter = ({
    routeType,
    getStationInfoRoutes,
    getExceptedRoutesForStation,
    stationId,
    addRouteException,
    removeRouteException,
    isExceptedTransportType,
    toggleExceptedRoutes,
}) => {
    const intl = useIntl()

    const { id, shortname } = routeType
    const routes = getStationInfoRoutes(shortname)
    const exceptedRoutes = getExceptedRoutesForStation(stationId)

    const toggleRouteException = useCallback(
        (routeIds, hasIntersects) => {
            if (hasIntersects) {
                removeRouteException(routeIds, shortname)
            } else {
                addRouteException(routeIds, shortname)
            }
        },
        [addRouteException, removeRouteException, shortname],
    )

    const exceptCheckboxClicked = useCallback(() => {
        toggleExceptedRoutes(shortname, isExceptedTransportType(shortname))
    }, [toggleExceptedRoutes, isExceptedTransportType, shortname])

    if (routes.length === 0) {
        return null
    }

    return (
        <div className="routes-type-line" key={id}>
            <div onClick={exceptCheckboxClicked} aria-hidden>
                <CheckBox checked={!isExceptedTransportType(shortname)} className={shortname} />
            </div>
            <Accordition title={intl.formatMessage({ id:`components.Accordition.title.${shortname}` })} titleClassName="transport-type-title">
                <div className="sfp-rf-group">
                    {routes.map((route) => {
                        let className = `sfp-rf-group-item ${shortname}`
                        const hasIntersects = intersects(route.route_ids, exceptedRoutes)
                        if (hasIntersects) {
                            className += ' excepted'
                        }
                        return (
                            <div
                                className={className}
                                key={route.route_num}
                                onClick={toggleRouteException.bind(null, route.route_ids, hasIntersects)}
                                aria-hidden
                            >
                                {route.route_num}
                            </div>
                        )
                    })}
                </div>
            </Accordition>
        </div>
    )
}

export default connect(
    state => ({
        getStationInfoRoutes: getStationInfoRoutes(state),
        getExceptedRoutesForStation: getExceptedRoutesForStation(state),
        isExceptedTransportType: isExceptedTransportTypeForCurrentStation(state),
    }),
    { toggleExceptedRoutes },
)(RoutesFilter)
