import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { IconWheelchair, IconConditioner } from '../../../resources/icons'

import transliterator from '../../../services/transliterator'

import './ForecastCard.scss'

const propTypes = {
    transportsIconsVisible: PropTypes.bool.isRequired,
    transportsNumberVisible: PropTypes.bool.isRequired,
    forecast: PropTypes.shape({
        arrt: PropTypes.number.isRequired,
        last: PropTypes.string.isRequired,
        where: PropTypes.string.isRequired,
        gos_num: PropTypes.string.isRequired,
        obj_tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        state_id: PropTypes.number.isRequired,
    }).isRequired,
}

const ForecastCard = ({ transportsIconsVisible, transportsNumberVisible, forecast }) => {
    let forecastCardClassName = 'sfp-grid-forecast-card-wrapper'
    if (forecast.state_id === 4) {
        // on final stop
        forecastCardClassName += ' sfp-grid-forecast-card-wrapper-on-final'
    }

    return (
        <div className={forecastCardClassName}>
            <div className="transport-waiting-time">
                {forecast.state_id === 4 ? (
                    <FormattedMessage id="pages.stationForecasts.title.menu.onFinal" />
                ) : (
                    <>
                        {Math.round(forecast.arrt / 60)}
                        <FormattedMessage id="pages.stationForecasts.title.menu.min" />
                    </>
                )}
            </div>
            {transportsIconsVisible && (
                <div className="transports-icons">
                    {forecast.obj_tags.map((iconType, i) => {
                        switch (iconType) {
                            case '2':
                                return <IconWheelchair key={iconType} />
                            case '3':
                                return <IconConditioner key={iconType} color="#45C1FF" />
                            default:
                                return null
                        }
                    })}
                </div>
            )}
            {transportsNumberVisible && <div className="transports-number">{forecast.gos_num}</div>}
        </div>
    )
}

ForecastCard.propTypes = propTypes

export default ForecastCard
