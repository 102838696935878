import React from 'react'
import { connect } from 'react-redux'

import { getStationInfo } from '../../store/schoolBusSchedulePageSlice'
import { IconStation } from '../../resources/icons'

import './StationInfo.scss'


/* eslint react/prop-types: ["off"] */
const StationInfo = ({ stationInfo }) => {
    const { name, description, routeTypes = [] } = stationInfo || {}

    return (
        <div className="sfp-station-info-wrapper">
            <IconStation routeTypes={routeTypes} iconType="place" />
            <div className="text-wrapper">
                <div className="title">
                    {name}
                </div>
                <div className="description">{description}</div>
            </div>
        </div>
    )
}

export default connect(state => ({
    stationInfo: getStationInfo(state),
}))(StationInfo)
