import React from 'react'
import { Switch, Route } from 'react-router-dom'

import HomePage from './pages/HomePage'
import StationForecastsPage from './pages/StationForecastPage'
import StationSearchByTypePage from './pages/StationSearchByTypePage'
import StationSearchByKeywordPage from './pages/StationSearchByKeywordPage'
import StationSearchByLocationPage from './pages/StationSearchByLocationPage'
import FavoritesPage from './pages/FavoritesPage'
import HistoryPage from './pages/HistoryPage'
import MapPage from './pages/MapPage'
import SchoolBusStationSchedulePage from './pages/SchoolBusStationSchedulePage'

export default () => (
    <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/station_forecasts" component={StationForecastsPage} />
        <Route path="/station_search_by_type" component={StationSearchByTypePage} />
        <Route path="/station_search_by_keyword" component={StationSearchByKeywordPage} />
        <Route path="/station_search_by_location" component={StationSearchByLocationPage} />
        <Route path="/map" component={MapPage} />
        <Route path="/favorites" component={FavoritesPage} />
        <Route path="/history" component={HistoryPage} />
        {/* <Route path="/schoolbus_station_schedule" component={SchoolBusStationSchedulePage} /> */}
        {/* <Route>
            <Redirect to="/" />
        </Route> */}
    </Switch>
)
