import React from "react"
import { connect } from "react-redux"

import dayjs from "dayjs"
import dayjs_relativeTime from "dayjs/plugin/relativeTime"

import { useIntl } from "react-intl"
import { getSchedules } from '../../store/schoolBusSchedulePageSlice'

import "./ScheduleView.scss"
import { IconTable } from "../../resources/icons"

dayjs.extend(dayjs_relativeTime)

const ScheduleView = ({ schedules }) => {

    return (
        <div className="sfp-school-bus-schedule-view-wrapper">
            {schedules.map((schedule, index) => {
                return (
                    <div className="schedule-route" key={index}>
                        <div className="schedule-route-header"><IconTable /><span>{schedule.routeName}</span></div>
                        <div className="schedule-items-container">
                            {schedule?.scheduleTable?.map((scheduleItem, index) => {
                                return (<ScheduleItem key={index} scheduleItem={scheduleItem} />)
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const ScheduleItem = ({ scheduleItem }) => {
    const intl = useIntl()

    return (
        <div className="schedule-item">
            <div className="forecast">
                <div className="arrival-time">{dayjs(scheduleItem.scheduleTime).fromNow()}</div>
                {/* <Link to={}>на карте</Link> */}
                <a className="map-link" href="#">
                    <IconTable />
                    <span>{
                        intl.formatMessage({ id: "pages.schoolBusStationSchedule.scheduleView.onMap" })
                    }</span>
                </a>
            </div>
            <div className="vehicle">
                <div className="vehicleName">
                    <IconTable />
                    <span>{scheduleItem.vehicleName}</span>
                </div>
                <div className="driverName">{scheduleItem.driverFIO}</div>
            </div>
        </div>
    )
}

export default connect((state) => ({ schedules: getSchedules(state) }))(ScheduleView)